import React, { FC, useEffect, useState } from 'react';
import { Card } from '../../../../base/card/card';
import { HistoryOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import './card.scss';
import { i18n } from '../../../../../i18n';
import { Steps, Input, Button, Tooltip } from 'antd';
import authSelectors from '../../../../../modules/auth/authSelectors';
import CollapsableCard from 'src/components/base/collapsable/collapsable';
import { useSelector } from 'react-redux';
import leadSelectors from 'src/modules/lead/leadSelectors';
import NotesDetailsModal from 'src/components/modal/NotesDetailsModal/NotesDetailsModal';
import EntityLogService from 'src/modules/entityLog/entitLogService';
import LeadService from 'src/modules/lead/leadService';
import Message from 'src/view/shared/message';

interface HistoryCardProps {
  record: any;
  leadId?: string;
}

const { Step } = Steps;

export const HistoryCard: FC<HistoryCardProps> = ({ record, leadId }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<any>([]);
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const [editableText, setEditableText] = useState<string>('');
  const currentUserId = useSelector(authSelectors.selectCurrentUser);
  const entityLogPermissionToRead = useSelector(leadSelectors.entityLogPermissionToRead);
  const [EntitlyLog, SetEntityLog] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = async (id) => {
    setModalVisible(true);

    try {
      const data = await EntityLogService.list(id);
      const formattedData = data.map((item) => ({
        key: item.id,
        old: item.details.changeValue,
        new: item.details.newValue,
        date: format(new Date(item.createdAt), 'yyyy-MM-dd'),
        user: item.createdBy?.email || 'Unknown User',
      }));
      SetEntityLog(formattedData);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (record !== undefined) {
      const sortedRecord = [...record].sort((a, b) => {
        let dateA: any = new Date(a.date);
        let dateB: any = new Date(b.date);
        return dateA - dateB;
      });
      setHistory(sortedRecord);
    }
  }, [record]);

  const handleEditClick = (index: number, text: string) => {
    setEditableIndex(index);
    setEditableText(text);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableText(e.target.value);
  };

  const updateItem = (id, newValue) => {
    setHistory((prevItems) => prevItems.map((item) => (item.id === id ? { ...item, notice: newValue } : item)));
  };

  const handleSaveClick = async (item, editableText) => {
    setLoading(true);
    try {
      const payload = {
        dealHistory: { ...item, notice: editableText, oldNotice: item.notice },
      };
      const { id } = await LeadService.update(leadId, payload);
      if (id) {
        updateItem(item.id, editableText);
        setEditableIndex(null);
        Message.success('Hinweis erfolgreich aktualisiert');
      }
    } catch (error) {
      Message.error('Fehler beim Aktualisieren der Notiz');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setEditableIndex(null);
  };

  return (
    <CollapsableCard title={'Kontaktverlauf'} icon={<HistoryOutlined />} panelKey="lead-history-card">
      <Steps direction="vertical">
        {[...history].reverse().map((item: any, index: number) => {
          const originalIndex = history.length - 1 - index;
          const noticeTitle = item.notice ? item.notice : '';
          return (
            <Step
              key={originalIndex}
              status={'finish'}
              title={item.dealStage === 'new' ? 'Neu' : i18n(`entities.lead.enumerators.dealStage.${item.dealStage}`)}
              description={
                <div>
                  <span>
                    {item.date !== undefined ? format(Date.parse(item.date.toString()), 'dd.MM.yyyy HH:mm') : ''}
                  </span>
                  <span>{item?.createdBy ? `Erstellt von: ${item?.createdBy?.email}` : ''}</span>
                  <br />
                  {editableIndex === originalIndex ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <Input value={editableText} onChange={handleTextChange} />
                      <div style={{ display: 'flex', gap: '15px' }}>
                        <Button disabled={loading} type="primary" onClick={() => handleSaveClick(item, editableText)}>
                          Save
                        </Button>
                        <Button type="primary" onClick={handleCancelClick}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                      <div>
                        <strong style={{ color: 'black', display: 'inline' }}>{noticeTitle}</strong>
                        {item.notice && currentUserId.id === item?.createdBy?.id && (
                          <Tooltip title={'Bearbeiten'}>
                            <EditOutlined
                              onClick={() => handleEditClick(originalIndex, noticeTitle)}
                              style={{ marginLeft: '8px' }}
                            />
                          </Tooltip>
                        )}
                      </div>

                      {entityLogPermissionToRead && item.notice && (
                        <Tooltip title={i18n(`common.details`)}>
                          <MoreOutlined
                            onClick={() => {
                              doOpenModal(item.id);
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              }
            />
          );
        })}
      </Steps>
      <NotesDetailsModal visible={modalVisible} onCancel={doCloseModal} data={EntitlyLog} />
    </CollapsableCard>
  );
};

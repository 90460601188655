import config from 'src/config';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },
  
  {
    path: '/integration',
    loader: () => import('src/view/auth/IntegrationPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () => import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () => import('src/view/tenant/list/TenantListPage'),
    permissionRequired: permissions.tenantRead,
    exact: true,
  },
  {
    path: '/current-tenant',
    loader: () => import('src/view/tenant/dashboard/CurrentTenant'),
    permissionRequired: permissions.currentTenantRead,
    exact: true,
  },
  {
    path: '/dashboard',
    loader: () => import('src/view/tenant/dashboard/TenantDashboard'),
    permissionRequired: permissions.tenantDashboard,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantCreate,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () => import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantEdit,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () => import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id/assign',
    loader: () => import('src/view/user/assign/UserAssignPage'),
    permissionRequired: permissions.userAssign,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () => import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/lead',
    loader: () => import('src/view/lead/list/LeadListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/lead/new',
    loader: () => import('src/view/lead/form/LeadFormPage'),
    permissionRequired: permissions.leadCreate,
    exact: true,
  },
  {
    path: '/lead/importer',
    loader: () => import('src/view/lead/importer/LeadImporterPage'),
    permissionRequired: permissions.leadImport,
    exact: true,
  },
  {
    path: '/lead/:id/edit',
    loader: () => import('src/view/lead/form/LeadFormPage'),
    permissionRequired: permissions.leadEdit,
    exact: true,
  },
  {
    path: '/lead/:id',
    loader: () => import('src/view/lead/list/LeadListPage'),
    permissionRequired: permissions.leadRead,
    exact: true,
  },
  
  {
    path: '/library/:folderId',
    loader: () => import('src/view/drive/view/DriveViewPage'),
    permissionRequired: permissions.driveRead,
    exact: true,
  },

  {
    path: '/library',
    loader: () => import('src/view/drive/view/DriveViewPage'),
    permissionRequired: permissions.driveRead,
    exact: true,
  },

  {
    path: '/applicant',
    loader: () => import('src/view/applicant/list/ApplicantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/applicant/new',
    loader: () => import('src/view/applicant/form/ApplicantFormPage'),
    permissionRequired: permissions.applicantCreate,
    exact: true,
  },
  {
    path: '/applicant/importer',
    loader: () => import('src/view/applicant/importer/ApplicantImporterPage'),
    permissionRequired: permissions.applicantImport,
    exact: true,
  },
  {
    path: '/applicant/:id/edit',
    loader: () => import('src/view/applicant/form/ApplicantFormPage'),
    permissionRequired: permissions.applicantEdit,
    exact: true,
  },
  {
    path: '/applicant/:id',
    loader: () => import('src/view/applicant/list/ApplicantListPage'),
    permissionRequired: permissions.applicantRead,
    exact: true,
  },
  {
    path: '/search',
    loader: () => import('src/view/search/list/SearchListPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/product',
    loader: () => import('src/view/product/list/ProductListPage'),
    permissionRequired: permissions.productRead,
    exact: true,
  },
  {
    path: '/product/new',
    loader: () => import('src/view/product/form/ProductFormPage'),
    permissionRequired: permissions.productCreate,
    exact: true,
  },
  {
    path: '/product/importer',
    loader: () => import('src/view/product/importer/ProductImporterPage'),
    permissionRequired: permissions.productImport,
    exact: true,
  },
  {
    path: '/product/:id/edit',
    loader: () => import('src/view/product/form/ProductFormPage'),
    permissionRequired: permissions.productEdit,
    exact: true,
  },
  {
    path: '/product/:id',
    loader: () => import('src/view/product/view/ProductViewPage'),
    permissionRequired: permissions.productRead,
    exact: true,
  },

  {
    path: '/job',
    loader: () => import('src/view/job/list/JobListPage'),
    permissionRequired: permissions.jobRead,
    exact: true,
  },
  {
    path: '/job/new',
    loader: () => import('src/view/job/form/JobFormPage'),
    permissionRequired: permissions.jobCreate,
    exact: true,
  },
  {
    path: '/job/importer',
    loader: () => import('src/view/job/importer/JobImporterPage'),
    permissionRequired: permissions.jobImport,
    exact: true,
  },
  {
    path: '/job/:id/edit',
    loader: () => import('src/view/job/form/JobFormPage'),
    permissionRequired: permissions.jobEdit,
    exact: true,
  },
  {
    path: '/job/:id',
    loader: () => import('src/view/job/view/JobViewPage'),
    permissionRequired: permissions.jobRead,
    exact: true,
  },
  {
    path: '/sheet',
    loader: () => import('src/view/sheet/list/SheetListPage'),
    permissionRequired: permissions.sheetRead,
    exact: true,
  },
  {
    path: '/supplier',
    loader: () => import('src/view/supplier/list/SupplierListPage'),
    permissionRequired: permissions.supplierRead,
    exact: true,
  },
  {
    path: '/supplier/new',
    loader: () => import('src/view/supplier/form/SupplierFormPage'),
    permissionRequired: permissions.supplierCreate,
    exact: true,
  },
  // {
  //   path: '/product/importer',
  //   loader: () => import('src/view/product/importer/ProductImporterPage'),
  //   permissionRequired: permissions.productImport,
  //   exact: true,
  // },
  {
    path: '/supplier/:id/edit',
    loader: () => import('src/view/supplier/form/SupplierFormPage'),
    permissionRequired: permissions.supplierEdit,
    exact: true,
  },
  {
    path: '/supplier/:id',
    loader: () => import('src/view/supplier/view/SupplierViewPage'),
    permissionRequired: permissions.supplierRead,
    exact: true,
  },

  {
    path: '/campaign',
    loader: () => import('src/view/campaign/list/CampaignListPage'),
    permissionRequired: permissions.campaignRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () => import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () => import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};

import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class UserService {
  static async edit(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/user`, body);

    return response.data;
  }

  static async editRole(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/user/role`, body);

    return response.data;
  }


  static async editRoleToAll(data) {
    console.log(data);
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/user/role/all`, body);

    return response.data;
  }

  static async editAllow(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/user/allow`, body);

    return response.data;
  }

  static async editBannerContacted(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/user/banner-edit`, body);

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();
    console.log('ids', ids);
    const response = await authAxios.delete(`/tenant/${tenantId}/user`, {
      params,
    });

    return response.data;
  }

  static async activate(id) {
    const data = {
      id,
    };

    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(`/tenant/${tenantId}/user/activate`, {
      data,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/user`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/user/import`, body);

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(`/tenant/${tenantId}/user/${id}`);
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/user`, {
      params,
    });

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/user/autocomplete`, {
      params,
    });
    return response.data;
  }

  // static async generatePassword(data) {
  //   const body = {
  //     data,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.put(`/tenant/${tenantId}/user/generate-password`, body);

  //   return response.data;
  // }

  static async sendCredentials(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/user/${id}/send-credentials`);

    return response.data;
  }
}

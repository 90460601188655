import React, {FC, ReactChild, ReactChildren} from 'react';
import './row.scss';

interface RowProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Row: FC<RowProps> = ({ children }) => {
  return(
    <div className="row">
      { children }
    </div>
  );
};
